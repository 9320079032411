import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const UI = () => {
    const blockContainerClass = classNames(styles.kitBlock, 'container')
    return (
        <section className={styles.ui}>
            <h3 className={styles.title}>UI kit</h3>
            <div className={blockContainerClass}>
                <div className={styles.colors} />
            </div>
            <div className={blockContainerClass}>
                <div className={styles.buttons} />
            </div>
            <div className={blockContainerClass}>
                <div className={styles.icons} />
            </div>
        </section>
    )
}

export default UI
