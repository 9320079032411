import landingMobile from 'src/images/optimized/png/CalatonCase/landingMobile.png'
import retinaLandingMobile from 'src/images/optimized/png/CalatonCase/landingMobile@2x.png'
import landingDesktop from 'src/images/optimized/png/CalatonCase/landingDesktop.png'
import retinaLandingDesktop from 'src/images/optimized/png/CalatonCase/landingDesktop@2x.png'

export const landing = {
    source: [
        {
            srcSet: landingMobile,
            retina: retinaLandingMobile,
            media: '(max-width: 699px)',
        },
        {
            srcSet: landingDesktop,
            retina: retinaLandingDesktop,
            media: '(min-width: 700px)',
        },
    ],
    src: landingDesktop,
    retina: retinaLandingDesktop,
    alt: 'Calaton Systems landing page',
}
