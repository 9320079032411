import React from 'react'

import Task from 'src/components/CalatonCase/Task'
import UI from 'src/components/CalatonCase/UI'
import LandingPage from 'src/components/CalatonCase/LandingPage'
import OtherCases from 'src/components/OtherCases'
import LetsTalk from 'src/components/LetsTalk'
import webBrief from '../../docs/Web-brief.pdf'

import styles from './styles.module.scss'

const CalatonCase = () => {
    return (
        <main className={styles.calatonStudioBrandingWebCase}>
            <Task />
            <UI />
            <LandingPage />
            <OtherCases filter="webDesign" />
            <LetsTalk brief={{ name: 'Web-brief', file: webBrief }} />
        </main>
    )
}

export default CalatonCase
