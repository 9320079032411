import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const Task = () => {
    const containerDivClass = classNames('container', styles.wrapper)
    return (
        <section className={styles.task}>
            <h3 className={styles.title}>
                Calaton Systems <span className={styles.break}>Web & UI/UX Design</span>
            </h3>
            <div className={containerDivClass}>
                <div className={styles.textBlock}>
                    <p className={styles.text}>
                        The task was to create an identity for an IT company from scratch, with only
                        the name Calaton available.The preference was given to a minimalistic modern
                        style with a variable logo.
                    </p>
                </div>
                <div className={styles.textBlock}>
                    <p className={styles.text}>
                        In the process of work, we were able to find a balance between the
                        seriousness and manufacturability of the IT sphere and a modern bright and
                        playful creative accent.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Task
