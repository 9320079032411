import React from 'react'

import Picture from 'src/components/Picture'
import { landing } from './landingImg'

import styles from './styles.module.scss'

const LandingPage = () => {
    return (
        <section className={styles.landing}>
            <h3 className={styles.title}>Landing page</h3>
            <div className={styles.relative}>
                <Picture className={styles.image} pic={landing} />
                <div className={styles.card} />
            </div>
        </section>
    )
}

export default LandingPage
